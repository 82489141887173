import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const LicensePage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="License"
        title="Home"
        keywords={[
          `mockups`,
          `graphic`,
          `design`,
          `iphone`,
          "iphone 12",
          "iphone 12 mockups",
        ]}
      />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>License Agreement</h2>
          <b>
            By purchasing resource (“item” or “file”) you are being granted a
            license to use these files for specific uses under certain
            conditions. Ownership remains with Palapa Sp. z o. o., and you are
            required to abide by the following licensing terms.
          </b>
          ‍<h3>Rights</h3>
          <p>
            You have rights for royalty free use of our resources for any or all
            of your personal and commercial projects. You may modify the
            resources according to your requirements and use them royalty free
            in any or all of your personal and commercial projects. For example,
            you may include this resource in a website you will be designing for
            a client. You are not required to attribute or link to
            minima.palapa.tech in any of projects. minima mockups will not be
            responsible for any outcome that may occur during the course of
            usage of our resources. We reserve the rights to change prices and
            revise the resources usage policy in any moment.
          </p>
          ‍<h3>Prohibitions</h3>
          <p>
            You do not have the rights to redistribute, resell, lease, license,
            sub-license or offer the file downloaded to any third party. For any
            resalable web applications or software programs, you should not
            include our graphic resources as an additional attachment. This will
            be considered as a redistribution of our resources which is
            forbidden by us. UNDER NO CIRCUMSTANCES SHALL minima.palapa.tech
            team BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA
            OR PROFIT, ARISING OUT OF THE USE, OR THE INABILITY TO USE, THE
            MATERIALS ON THIS SITE, EVEN IF minima.palapa.tech team OR AN
            AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. IF YOUR USE OF MATERIALS FROM THIS SITE RESULTS IN THE
            NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU
            ASSUME ANY COSTS THEREOF.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <LicensePage location={props.location} data={data} {...props} />
    )}
  />
)
